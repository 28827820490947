//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "address",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false
    },
    updateEngineer: {
      type: Boolean,
      default: false
    },
    engineer: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      swatches: [["#FF0000", "#AA0000", "#550000"], ["#FFFF00", "#AAAA00", "#555500"], ["#00FF00", "#00AA00", "#005500"], ["#00FFFF", "#00AAAA", "#005555"], ["#0000FF", "#0000AA", "#000055"]],
      activeColor: {
        alpha: 1,
        hex: "#00BCD4",
        hexa: "#00BCD4FF",
        hsla: {
          a: 1,
          h: 186.79245283018867,
          l: 0.41568627450980394,
          s: 1
        },
        hsva: {
          a: 1,
          h: 186.79245283018867,
          s: 1,
          v: 0.8313725490196079
        },
        hue: 186.79245283018867,
        rgba: {
          a: 1,
          b: 212,
          g: 188,
          r: 0
        }
      },
      fieldDescriptions: []
    };
  },
  watch: {
    activeColor: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        var _this = this;

        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:color", param);
        }, _this.timeoutLimit);
      }
    },
    engineer: function engineer() {
      this.setColor();
    }
  },
  methods: {
    setColor: function setColor() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.activeColor.hexa = _this.engineer.color;
      }
    }
  },
  mounted: function mounted() {
    this.setColor();
  }
};