import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PersonalInfo from "@/view/pages/engineer/create-or-update/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/create-or-update/Emergency-Contact";
import StaffAddress from "@/view/pages/engineer/create-or-update/Engineer-Address";
import ColorTemplate from "@/view/pages/engineer/create-or-update/Color";
import StaffMixin from "@/core/lib/engineer/engineer.mixin";
export default {
  mixins: [CommonMixin, StaffMixin],
  name: "manage-engineer-create",
  title: "Create Staff",
  components: {
    PersonalInfo: PersonalInfo,
    EmergencyContact: EmergencyContact,
    StaffAddress: StaffAddress,
    ColorTemplate: ColorTemplate
  },
  watch: {
    engineer: function engineer(param) {
      if (param > 0) {
        this.getStaffDetails();
      }
    }
  },
  methods: {
    getStaffDetails: function getStaffDetails() {
      var _this = this;

      if (_this.engineer) {
        _this.getEngineer().then(function (response) {
          _this.engineerArr = response;

          _this.$store.dispatch(SET_BREADCRUMB, [{
            title: "Staff",
            route: "engineer"
          }, {
            title: "Update"
          }, {
            title: response.barcode
          }]);
        }).catch(function (error) {
          _this.logError(error);

          _this.$router.go(-1);
        }).finally(function () {
          _this.pageLoading = false;
        });
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Staff",
      route: "engineer"
    }, {
      title: "Create"
    }]);

    _this.getStaffDetails();
  },
  computed: {
    getStaffName: function getStaffName() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.engineerArr) === false) {
        return _this.engineerArr.full_name;
      }

      return null;
    }
  },
  created: function created() {
    var _this = this;

    if (_this.$route.name == "admin.engineer.update" || _this.$route.name == "engineer.engineer.update") {
      _this.updateStaff = true;
      _this.pageLoading = true;
      _this.engineer = _this.$route.params.id;

      if (!_this.engineer || _this.engineer <= 0) {
        _this.$router.go(-1);
      }
    }
  }
};