import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PATCH } from "@/core/services/store/request.module"; //import {cloneDeep} from "lodash";

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    e_designation: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    }
  },
  watch: {
    e_designation: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.eDesignation = this.lodash.cloneDeep(param);
      }
    }
  },
  data: function data() {
    return {
      pageLoading: false,
      eDesignation: [{
        title: null
      }]
    };
  },
  methods: {
    updateContractSetting: function updateContractSetting() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "setting/e-designation",
        data: {
          e_designation: _this.eDesignation
        }
      }).then(function () {
        _this.$emit("close-dialog", true);

        _this.$emit("get-e-designation", true);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    createStaffDesignation: function createStaffDesignation() {
      this.eDesignation.push({
        value: null
      });
    },
    deleteContractType: function deleteContractType(index) {
      if (this.eDesignation.length > 1) {
        this.eDesignation.splice(index, 1);
      }
    }
  },
  components: {
    Dialog: Dialog
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 50);
    }
  }
};