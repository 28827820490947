import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { QUERY } from "@/core/services/store/request.module";
export default {
  name: "Engineer",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false
    },
    updateEngineer: {
      type: Boolean,
      default: false
    },
    engineer: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      timeoutPreLimit: 500,
      timeoutPre: null,
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      autocompleteList: new Array(),
      searchAddress: null,
      address: {
        street_1: null,
        street_2: null,
        unit_no: null,
        postal_code: null
      },
      fieldDescriptions: []
    };
  },
  watch: {
    address: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        var _this = this;

        clearTimeout(_this.timeoutPre);
        _this.timeoutPre = setTimeout(function () {
          EngineerEventBus.$emit("update:address", param);
        }, _this.timeoutPreLimit);
      }
    },
    engineer: function engineer() {
      this.setEngineerAddress();
    }
  },
  methods: {
    setEngineerAddress: function setEngineerAddress() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.address.street_1 = _this.engineer.address_line_1;
        _this.address.street_2 = _this.engineer.address_line_2;
        _this.address.unit_no = _this.engineer.unit_number;
        _this.address.postal_code = _this.engineer.postal_code;
      }
    },
    selectGoogleAddress: function selectGoogleAddress(index) {
      var _this = this;

      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        var place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;

        _this.$store.dispatch(QUERY, {
          url: "google-api/place-detail",
          data: {
            "place-id": place_id
          }
        }).then(function (response) {
          _this.formatAddress(response.data);
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.clearAddress();

          _this.autocompleteList = new Array();
          _this.autocompleteLoading = false;
        });
      }
    },
    getGoogleAddress: function getGoogleAddress() {
      var _this = this;

      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store.dispatch(QUERY, {
              url: "google-api/autocomplete",
              data: {
                search: _this.searchAddress
              }
            }).then(function (response) {
              _this.autocompleteList = response.data;
            }).catch(function (error) {
              _this.logError(error);
            }).finally(function () {
              _this.autocompleteLoading = false;
            });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    clearAddress: function clearAddress() {
      var _this = this;

      _this.searchAddress = null;
      _this.address.street_1 = null;
      _this.address.street_2 = null;
      _this.address.unit_no = null;
      _this.address.postal_code = null;
    },
    formatAddress: function formatAddress(param) {
      var _this = this;

      try {
        _this.autocompleteLoading = true;

        _this.formatGPSAddress(param).then(function (result) {
          if (_this.lodash.isEmpty(param) === false) {
            _this.searchAddress = null;
            _this.address.street_1 = result.street_1;
            _this.address.street_2 = result.street_2;
            _this.address.unit_no = result.unit_no;
            _this.address.postal_code = result.zip_code;
          }
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.autocompleteLoading = false;
        });
      } catch (error) {
        _this.logError(error);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.setEngineerAddress();
  }
};